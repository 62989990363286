import React, { Component, Fragment } from 'react'
import gql from '../../api/gql'
import { Table, Button, Icon, Drawer, Select, Divider, Col, Row, notification, Tooltip, Popconfirm } from 'antd'
import SiteAdd from '../../components/sites/SiteAdd'
import SiteEdit from '../../components/sites/SiteEdit'
import { history } from '../../history'
import { setCurrentUser } from '../../actions'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlug } from '@fortawesome/free-solid-svg-icons'
import { SHOPIFY_API, API_URL, APP_URL, SHOPBASE_API } from '../../config'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'


const { Option } = Select
// const isSeller = this.props.currentUser.roles.find(role => role.name === "Seller");
const Container = styled.div`
  @media only screen and (max-width: 768px) {
    .p-filter{
      display: flex;
      flex-direction: ${ props => !props.isSeller ?  "column-reverse" : "inherit"} ;
      justify-content: ${props => props.isSeller ? "space-between" : "inherit"} 
      .btn-add{
        text-align: right
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .btn-add{
      text-align: right
    }
  }
`
class SitesList extends Component {
  state = {
    sites: [],
    users: [],
    total: 0,
    loading: false,
    selectedSite: null,
    createSite: false,
    filter: {
      user_id: null,
      offset: 0,
      limit: 20,
      verified: true,
      ...this.props.filter
    },
    page: 1
  }
  handleChange = value => {
    this.setState({
      filter: {
        ...this.state.filter,
        verified: value.key === "0" ? false : true
      }
    }, () => this.loadSites())
  }
  componentDidMount() {
    this.setState({
      filter: {
        ...this.state.filter,
        verified: true
      }
    }, () => this.loadSites())
    this.getUserID()
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        filter: { ...filter, offset: 0, ...this.props.filter }
      }, () => this.loadSites())
    }
  }

  loadSites() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const seller = currentUser.roles.find(role => role.name === "Seller")
    const query = `query SitesList($filter: SiteFilter){
      sites(filter: $filter){
        count
        hits{
          id
          title
          domain
          platform
          status
          verified
          type
          amz_key_oop
          user{
            id
            first_name
            last_name
          }
          webhooks{
            id
            topic
            address
            delivery_url
          }
        }
      }
    }`
    this.setState({
      loading: true
    })
    gql.request(query, {
      filter:
        seller ? {
          ...this.state.filter,
          user_id: this.props.currentUser.id
        } : { ...this.state.filter }
    }).then(res => {
      this.setState({
        sites: res.sites.hits || [],
        total: res.sites.count,
        loading: false
      })
    })
  }

  getUserID() {
    const query = `query userByRole($role: String!, $status: Boolean!, $search: String){
      usersByRole(role:$role, status:$status, search: $search){
          id
          first_name
          last_name
      }
    }`

    this.setState({
      loading: true,
    })
    gql.request(query, {
      role: "Seller",
      status: true,
      search: "",

    }).then(res => {
      this.setState({
        users: res.usersByRole || [],
        loading: false
      })
    })
  }

  handleSelect = (id) => {
    this.setState({
      filter: {
        ...this.state.filter,
        user_id: id
      }
    }, () => this.loadSites())
  }

  updateWebhooks = id => {
    const query = `mutation updateWebhooks{updateWebhooks(id: "${id}")}`
    gql.request(query).then(res => {
      notification['success']({
        message: 'Webhooks updated'
      })
    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  deleteSite = id => {
    const query = `mutation deleteSite{deleteSite(id: ${id})}`
    gql.request(query).then(res => {
      notification['success']({
        message: 'Site has been disabled'
      })
      this.loadSites()
    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  reConnect = site => {
    const { id, platform, domain } = site
    switch (platform) {
      case 'woocommerce':
        var app_name = 'POD Fulfillment'
        var return_url = `${APP_URL}/sites`
        //var return_url = `${APP_URL}/sites`
        var callback_url = `${API_URL}/auth_callback/${id}`
        var redirectUrl = `${domain}/wc-auth/v1/authorize?app_name=${app_name}&scope=read_write&user_id=1&return_url=${return_url}&callback_url=${callback_url}`
        window.location = redirectUrl
        break
      case 'shopify':
        var url = domain
        var redirect_uri = `${API_URL}/auth_callback/shopify`
        var scopes = 'write_products,write_orders,write_fulfillments,write_inventory'
        var nonce = id
        window.location = `${url}/admin/oauth/authorize?client_id=${SHOPIFY_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`
        break
      case 'shopbase':
        // var url = domain
        // var redirect_uri = `${API_URL}/auth_callback/shopbase`
        // var scopes = 'write_products,write_orders,write_fulfillments'
        // var nonce = id
        window.location = `${url}/admin/oauth/authorize?client_id=${SHOPBASE_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`
        break
      default:
        break
    }
  }

  render() {
    const { sites, filter, total, loading, page, users, selectedSite } = this.state    
    let isSupporter = this.props.currentUser.roles.find(el => ['Supporter'].includes(el.name))
    const isSeller = this.props.currentUser.roles.find(role => role.name === "Seller")
    const columns = [
      {
        title: 'ID',
        key: 'id',
        dataIndex: 'id',
        width: 50,
      },
      {
        title: 'Site Name',
        key: 'title',
        dataIndex: 'title',
        width: 120,
        render: (title, { webhooks }) => (
          <div>
            <div>{title}</div>
            {/* {webhooks && webhooks.map(wh => <span key={wh.id} style={{fontSize:12,color:'#ddd'}}>{wh.topic} => {wh.delivery_url}</span>)} */}
          </div>
        )
      },
      {
        title: 'Site Url',
        key: 'domain',
        dataIndex: 'domain',
        width: 280,
        render: (domain, row) =>  row.platform !=="import"? <a href={domain} target="_blank" rel="noopener noreferrer">{domain}</a>: "-"
      },
      {
        title: 'Platform',
        key: 'platform',
        dataIndex: 'platform',
        width: 120,
        render: (row) => {
          switch (row) {
            case "shopbase":
              return <img alt="" src="/shopbase-logo.svg" width="80"></img>
            case "shopify":
              return <img alt="" src="/shopify-logo.png" width="80"></img>
            case "woocommerce":
              return <img alt="" src="/logo-woocommerce.png" width="100"></img>
            case "amz":
              return <img alt="" src="/amazon_logo.webp" width="65"></img>
            default:
              return <img alt="" src="/import-site.png" width="100"></img>
          }
        }
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        width: 100,
        render: (id, row) =>
          `${row.user.first_name} ${row.user.last_name}`
      },
      {
        title: 'Status',
        dataIndex: 'verified',
        key: 'verified',
        align: 'center',
        width: 100,
        render: (verified, site) => verified ?
          <FontAwesomeIcon icon={faPlug} style={{ color: site.platform === "import" || site.type === "offline"?'red':'green', fontSize: 18 }} /> :
          <Icon type="disconnect" style={{ color: 'red', fontSize: 18 }} onClick={() => this.reConnect(site)} />
      },
      {
        title: 'Amazon key',
        dataIndex: 'amz_key_oop',
        align: 'center',
        width: 100,
        render: (amz_key, record) => {
          if(!record.amz_key_oop) return;
          return <>
            <CopyToClipboard text={record.amz_key_oop} onCopy={() => alert("Text copied to clipboard!")}>
              <Button type="button" style={{
                color: "#333333",
                width: 160,
                overflow: "hidden"
              }}><Icon type="copy" /> {record.amz_key_oop}</Button>
            </CopyToClipboard>
          </>
        }
      },
      isSupporter ? {} :
        {
          title: 'Actions',
          key: 'actions',
          width: 210,
          dataIndex: 'id',
          align: 'right',
          render: (id, record) => {
            return (
              <div className="actions">
                <Tooltip title="Edit site">
                  <Button size="small"
                    onClick={() => {
                      this.setState({
                        selectedSite: record
                      })
                    }}>
                    <Icon type="edit" theme="twoTone"/>
                  </Button>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Settings">
                  <Button size="small" onClick={() => history.push(`/sites/${id}/settings`)}>
                    <Icon type="setting" style={{ color: "#ff9100" }} />
                  </Button>
                </Tooltip>
                {/* <Tooltip title="Update webhooks">
                  <Button size="small" onClick={() => this.updateWebhooks(id)}>
                    <Icon type="redo" style={{color:"blue"}}/>
                  </Button>
                </Tooltip>
                <Divider type="vertical" /> */}
                {
                  record.platform === "woocommerce" &&
                  <Fragment>
                    <Divider type="vertical" />
                    <Tooltip title="Mapping Products">
                      <Button onClick={e => history.push(`/sites/${id}/mapping-products`)} size="small"><Icon type="diff" /></Button>
                    </Tooltip>
                  </Fragment>
                }
                {
                  record.type === "offline" &&
                  <Fragment>
                    <Divider type="vertical" />
                    <Tooltip title="Import Orders">
                      <Button onClick={e => history.push(`/sites/${id}/import-orders`)} size="small"><Icon type="import" /></Button>
                    </Tooltip>
                  </Fragment>
                }
                <Divider type="vertical" />
                <Tooltip title="Delete site">
                  <Popconfirm
                    title="Are you sure delete this site?"
                    onConfirm={() => this.deleteSite(id)}
                    okText="Yes"
                    cancelText="No">
                    <Button size="small">
                      <Icon type="delete" style={{ color: "red" }} />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </div>
              // <Dropdown.Button onClick={e => {
              //   e.preventDefault()
              //   this.setState({ selectedSite: record })
              // }} overlay={<Menu>
              //   <Menu.Item onClick={() => {
              //     history.push(`/sites/${id}/settings`)
              //   }}>Settings</Menu.Item>
              //   <Menu.Item disabled={record.platform === 'shopify'} onClick={() => {
              //     history.push(`/sites/${id}/mapping-products`)
              //   }}>Mapping Products</Menu.Item>
              // </Menu>}>
              //   Edit
              // </Dropdown.Button>
            )
          }
        }
    ]
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadSites())
      }
    }
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container isSeller={isSeller}>
        <Row type="flex" className="p-filter">
          <Col md={12}>
            <Select
              labelInValue
              defaultValue={{ key: '1' }}
              style={{ width: 165, marginRight: 5, marginBottom:10 }}
              onChange={this.handleChange} >
              <Option value="1">Connected</Option>
              <Option value="0">Pending</Option>
            </Select>
            {!isSeller && (
              <Select
                showSearch
                style={{ width: 165, marginBottom:10}}
                placeholder="Seller"
                optionFilterProp="children"
                onChange={this.handleSelect}
              >
                <Option value={null}>All Seller</Option>
                {users.map((user) => (
                  <Option key={user.id} value={user.id}>{user.first_name} {user.last_name} </Option>
                ))}
              </Select>
            )}
          </Col>
          <Col md={12 } >
            {!isSupporter && (
              <div className="btn-add" style={{ marginBottom: "10px" }}> 
                <Button type="primary"
                  onClick={() => {
                    this.setState({
                      createSite: true
                    })
                  }}>
                  <Icon type="plus" /> New Site
                </Button>
              </div>
            )}
          </Col>
        </Row>
        <Table
          scroll={{x:tableWidth}}
          pagination={pagination}
          columns={columns}
          dataSource={sites}
          loading={loading}
          rowKey={(text, index) => index} />
        <div className="p-drawer-sites"> 
          <Drawer
            width="500px"
            title="Add Site"
            visible={this.state.createSite}
            placement="right"
            onClose={() => {
              this.setState({
                createSite: false
              })
            }}>
            <SiteAdd onSuccess={()=>{
              this.setState({createSite: false})
              this.loadSites()
            }}/>
          </Drawer>
        </div>
        <Drawer
          title="Edit Site"
          visible={(selectedSite !== null)}
          placement="right"
          width="500px"
          onClose={() => {
            this.setState({
              selectedSite: null
            })
          }}
        >
          {selectedSite && <SiteEdit site={selectedSite} onLoad={() => this.loadSites()}
            onClose={() => this.setState({
              selectedSite: null
            })} />}
        </Drawer>
      </Container >
    );
  }
}
export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    }
  },
  { setCurrentUser }
)(SitesList)