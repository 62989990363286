import React, { Component } from 'react'
import { Form, Input, Button,notification } from 'antd'
import { PropTypes } from 'prop-types'
import gql from '../../api/gql'
import _ from 'lodash'

class SiteEdit extends Component {
  state = {
    loading: false,
    sites: this.props.sites
  }
  updateSite = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      //console.log(values)
      if (!err) {
        const mutation = `mutation updateSite($site_id: Int!, $title: String!){
          updateSite(site_id: $site_id, title:$title){
            id
            title
            domain
            platform
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          title: values.title,
          site_id: this.props.site.id
        }).then(res => {
          notification['success']({ message: " Site has been updated" })
          this.setState({ loading: false })
          this.onLoad()
          this.onClose()
        })
        .catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }
  onLoad = () =>{
    this.props.onLoad()
  }
  onClose = () => {
    this.props.onClose(false);
  }
  
  render() {
    const { site, form } = this.props
    const { loading } = this.state
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.updateSite}>
        <Form.Item label="Site Name">
          {getFieldDecorator('title', {
            initialValue: site.title,
            rules: [{ required: true }]
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Site Url">
          {getFieldDecorator('domain', {
            initialValue: site.domain,
          })(
            <Input placeholder="https://your-store.myshopify.com" disabled={true}/>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Save</Button> {" "}
          <Button onClick={() => this.props.onClose()}>Cancel</Button>
        </Form.Item>
      </Form>
    )
  }
}

SiteEdit.propTypes = {
  site: PropTypes.object,
  onCompleted: PropTypes.func,
  onError: PropTypes.func
}

export default Form.create({ name: 'form' })(SiteEdit);